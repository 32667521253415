<template>
  <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
    <Breadcrumb :datas="breadcrumb_data" />
    <!--begin::Entry-->
    <div class="d-flex flex-column-fluid">
      <!--begin::Container-->
      <div class="container-fluid">
        <!--begin::Card-->
        <div class="card card-custom">
          <div class="card-header flex-wrap border-0 pt-6 pb-0">
            <div class="card-title">
              <h3 class="card-label">
                {{ $t("c_management.user_whitelist") }}
              </h3>
            </div>
          </div>
          <div class="card-body">
            <Datatable
              :dataUrl="datatableDataUrl"
              @data="loadDatatableData($event)"
            >
              <template v-slot:head>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">{{ $t("commons.coin") }}</th>
                  <th scope="col">{{ $t("commons.username") }}</th>
                  <th scope="col">{{ $t("commons.address") }}</th>
                  <th scope="col">{{ $t("commons.status") }}</th>
                  <th scope="col">{{ $t("commons.amlscore") }}</th>
                  <th scope="col">{{ $t("commons.proof") }}</th>
                  <th scope="col">{{ $t("commons.action") }}</th>
                </tr>
              </template>
              <template v-slot:body>
                <tr
                  v-for="(item, index) in whitelistAddressdata"
                  :key="'data-' + index"
                >
                  <th scope="row">{{ item.id }}</th>

                  <td>{{ item.asset_name }}</td>
                  <td>{{ item.user_name }}</td>
                  <td>
                    {{ item.address }}
                  </td>
                  <td>
                    <span :class="`badge ${getStatus(item.status)['badgeStyle']}`">{{ getStatus(item.status)['label'] }}</span>
                  </td>
                  <td>{{ item.amlScore }}</td>
                  <td>
                    <a v-bind:href="item.image_url" target="_blank"
                      >Click Here</a
                    >
                  </td>

                  <td>
                    <button
                      class="btn btn-primary text-break text-truncate generate-aml-btn"
                      v-if="item?.status == 'Pending'"
                      @click="generateAml(item)"
                      :disabled="loading"
                    >
                      Generate AML Report
                    </button>
                    &nbsp;<button
                      class="btn btn-danger"
                      @click="rejectaddress(item)"
                    >
                      Reject
                    </button>
                  </td>
                </tr>
              </template>
            </Datatable>
          </div>
          <!-- <DatatablePagination v-if="!this.isLoading" :totalCount="this.whitelistAddressdata.total" :pageSize="this.whitelistAddressdata.perPage" :currentPage="this.whitelistAddressdata.currentPage" :lastPage="this.whitelistAddressdata.lastPage" @changePage="changePage($event)" @changePageSize="changePageSize($event)" /> -->
        </div>
        <!--end::Card-->
      </div>
      <!--end::Container-->
    </div>
    <!--end::Entry-->
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Breadcrumb from "@/components/layout/Breadcrumb.vue";
// import DatatableDropdown from '@/components/datatable/Dropdown.vue'
import "bootstrap-daterangepicker";
// import DatatablePagination from "./DatatablePagination.vue";
// import AuditModal from '@/components/custom/AuditModal.vue';
import Datatable from "@/components/datatable/Datatable.vue";
import api_old from "@/api/index_old";

export default {
  name: "UserWhiteListAddress",
  data() {
    return {
      isLoading: true,
      datatableDataUrl: "",
      whitelistAddressdata: [],
      loading: false,
      // page: 1,
    };
  },
  components: {
    Breadcrumb,
    Datatable,
  },
  computed: {
    ...mapState({
      auditModalVisible: (state) => state.users.auditModalVisible,
      audits: (state) => state.users.audits,
      permissions: (state) => state.customer.permissions,
      lang: (state) => state.global.lang,
      user: (state) => state.customer.user,
    }),
    breadcrumb_data() {
      return {
        title: this.$t("c_management.title"),
        subtitle: [this.$t("c_management.all_customer")],
      };
    },
  },
  methods: {
    ...mapActions("customer", ["GENERATE_AML_ADDRESS", "REJECT_ADDRESS"]),
    loadDatatableData(data) {
      this.$set(this, "whitelistAddressdata", data);
    },
    checkPermission(permission) {
      return this.permissions?.some((val) => val.name == permission);
    },
    generateAml(item) {
      this.loading = true;
      setTimeout(() => {
        this.GENERATE_AML_ADDRESS({
          id: item.id,
          address: item.address,
          userId: item.user_id,
        });
        this.loading = false;
      }, 2000);
      // this.GENERATE_AML_ADDRESS({ id: item.id, address: item.address, userId: item.user_id })
    },
    rejectaddress(item) {
      this.REJECT_ADDRESS({ id: item.id, type: 1 });
    },
    getStatus(status) {
      switch (status) {
        case 'Active':
          return {label: status, badgeStyle: "badge-success"};
        case 'Blocked':
          return {label: status, badgeStyle: "badge-danger"};
        case 'Pending':
          return {label: status, badgeStyle: "badge-warning"};
        default:
          return {label: status, badgeStyle: "badge-secondary text-dark border border-dark"};
      }
    },
    // async changePage(page) {
    //         this.$set(this, "isLoading", true);
    //         this.$set(this, "page", page);
    //         this.loadData();
    //     },
  },
  created() {
    if (
      this.permissions?.length > 0 &&
      !this.checkPermission("crm.users.index")
    ) {
      this.$router.push("/no-permission");
    }
    this.datatableDataUrl = api_old.whitelistaddress;
  },
};
</script>
<style lang="scss" scoped>
.truncateText {
  min-width: 150px;
  overflow: hidden;
  position: relative;
  margin: 0 5px 0 5px;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
}
@media (max-width: 1692px) {
  .generate-aml-btn {
    margin-bottom: 5px;
  }
}
</style>
